

import AbstractComponentVue from '../services/AbstractComponentVue';
import {Component} from 'vue-property-decorator';
import UserCard from '@/components/UserCard.vue';
import {services} from '@/main';
import AlertService from '@/utils/AlertService';

@Component({
	components: {
		UserCard,
	},
})
export default class MailDeactivation extends AbstractComponentVue {
  private accountId: number = +this.$route.params.id;

  /**
   * Annule la réactivation du service mail.
   */
  private cancel(): void {
    services.routageService.goTo('/account/' + this.accountId + '/services');
  }

  /**
   * Annule la désactivation du service mail.
   */
  private reactivateMail(): void {
    this.showModal('modal-traitement-cours');
    const uri: string = '/api/v1/user-services/mail/' + this.accountId + '/cancel-deactivation';
    services.restService.callPatch(uri, null)
        .then((res) => {
          if (res !== undefined && res.request.status === 200) {
            AlertService.success('Votre demande a bien été prise en compte.');
            services.routageService.goTo('/account/' + this.accountId + '/services');
            services.loginService.ifCurrentUserReloadStore(this.accountId,
                () => this.hideModal('modal-traitement-cours'));
          } else {
            this.hideModal('modal-traitement-cours');
          }
        })
        .catch((respError) => {
          if (respError.request.status === 404) {
            // on affiche que l'utilisateur n'a pas été trouvé.
            AlertService.warning('L\'utilisateur concerné n\'a pas été trouvé.');
          } else if (respError.request.status === 403) {
            // Accès interdit
            AlertService.warning('Accès interdit. Vous ne disposez pas des autorisations nécessaires.');
          }
          this.hideModal('modal-traitement-cours');
        });
  }
}
